import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FaCircle } from "react-icons/fa";

import { colors } from "../../../../../assets/colors";
import requests from "../../../../../services/requests";
import Table from "../../../../../components/styles/Table";
import Container from "./styles";

const SLATables = ({ filterInformation, searchString, searchPeriod }) => {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const getData = () => {
        requests
            .dashboardProcessSla({
                search: searchString,
                filters: filterInformation,
                period: searchPeriod,
            })
            .then(res => {
                setData(res);
                setLoad(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <Container>
                    <Table>
                        <thead>
                            <tr>
                                <th>Processo </th>
                                <th>Chegada</th>
                                <th>T</th>
                                <th>Registro</th>
                                <th>T</th>
                                <th>Liberação (CI)</th>
                                <th>T</th>
                                <th>Faturamento</th>
                                <th>T</th>
                                <th>SLA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.table.map(row => (
                                <tr key={row.identifier}>
                                    <td className="whitespace-no-wrap">
                                        {row.code}
                                        {colors[`canal_${row.channel?.toLowerCase()}`] && (
                                            <FaCircle
                                                style={{ display: "inline" }}
                                                className="d-inline ml-1 mb-1"
                                                size={15}
                                                color={colors[`canal_${row.channel?.toLowerCase()}`]}
                                            />
                                        )}
                                    </td>
                                    <td>{row.arrivedAtFormatted}</td>
                                    <td>{row.tArrivedAt}</td>
                                    <td>{row.clearenceDiAtFormatted}</td>
                                    <td>{row.tClearenceDiAt}</td>
                                    <td>{row.clearenceCiAtFormatted}</td>
                                    <td>{row.tClearenceCiAt}</td>
                                    <td>{row.billedAtFormatted}</td>
                                    <td>{row.tBilledAt}</td>
                                    <td>{row.SLA}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div>
                        <strong>Média</strong>
                        <span>{data.average}</span>
                    </div>
                </Container>
            )}
        </>
    );
};

export default SLATables;
